.property-card
{
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../public/3746059.jpg) !important;
 
  display:-webkit-box;
  display:-ms-flexbox;

  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius:16px;
  overflow:hidden;
  -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow:  15px 15px 27px #c1c1c4;
  margin-top: 20px;
  height: 320px;
  background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.property-card1
{
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../public/researcher-female-lab.jpg) !important;
 
  display:-webkit-box;
  display:-ms-flexbox;

  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius:16px;
  overflow:hidden;
  -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow:  15px 15px 27px #c1c1c4;
  margin-top: 20px;
  height: 320px;
  background-position: center;
background-repeat: no-repeat;
background-size: cover;
}


.section1 {
  height:fit-content;
  width: 100%;
  display: grid;
  place-items: center;
}
.row1 {
  display: flex;
  flex-wrap: wrap;
}
.column1 {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}
.card1 {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #3C9BD6 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}
.h31 {
  font-size: 20px;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
}
.p1 {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}
.icon-wrapper {
  background-color: #3C9BD6;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.card1:hover {
  background-position: 0 100%;
}
.card1:hover .icon-wrapper {
  background-color: #ffffff;
  color: #2c7bfe;
}
.card1:hover h3 {
  color: #ffffff;
}
.card1:hover p {
  color: #f0f0f0;
}
@media screen and (min-width: 768px) {
  .section1 {
    padding: 0 2em;
  }
  .column1 {
    flex: 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .section1 {
    padding: 1em 3em;
  }
  .column1 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.property-card2
{
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../public/3672341.jpg) !important;
 
  display:-webkit-box;
  display:-ms-flexbox;

  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius:16px;
  overflow:hidden;
  -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow:  15px 15px 27px #c1c1c4;
  margin-top: 20px;
  height: 320px;
  background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
